<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        <i
          >You are evaluating a group's poster during an argumentation session for a new project.
          Which of the following critiques offered by the travellers to the group spokesperson use
          scientific concepts, theory, or laws to challenge the group's claim? (More than 1 answer
          is possible.)</i
        >
      </p>
      <p class="mb-2">
        Guiding Question: Which chemical equation best represents the reaction of sodium bicarbonate
        <stemble-latex content="$\ce{(NaHCO3)}$" />
        with acetic acid
        <stemble-latex content="$(CH3COOH)\text{?}$" />
      </p>
      <p class="mb-2">
        <b>Claim: </b>
        <stemble-latex
          content="$\ce{NaHCO_{3}}\text{ + }\ce{CH_{3}COOH}\text{ --> }\ce{H_{2}CO_{3}}\text{ + }\ce{CH3COO- Na+}$"
        />
      </p>
      <p class="mb-2">
        <b>Evidence:</b>
      </p>
      <ul class="mb-2">
        <li>Chemical equation balances.</li>
        <li>pH paper of resulting solution is neutral.</li>
      </ul>
      <p class="mb-2">
        <b>Reasoning:</b>
      </p>
      <ul class="mb-2">
        <li>
          Law of Conservation dictates that the mass of the products has to equal mass of the
          reactants.
          <stemble-latex content="$\ce{H_{2}CO_{3}}$" />
          is a weak acid and
          <stemble-latex content="$\ce{CH_{3}COO- Na+}$" />
          is a weak base so they cancel each other out, leading to a pH 7 solution.
        </li>
      </ul>

      <p class="mb-2" v-for="question in questions" :key="question.inputName">
        <v-checkbox v-model="inputs.studentAnswers" :value="question.value" class="pl-0 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="question.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S2_Q7',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      questions: [
        {
          text: 'Is the pKa of $\\ce{H_{2}CO_{3}}$ and the pKb of $\\ce{CH_{3}COO-}$ the same? If they are not, what does that indicate about your claim?',
          value: 'a',
          inputName: 'input1',
        },
        {
          text: 'How do you know mass is conserved when charges are present in the products.',
          value: 'b',
          inputName: 'input2',
        },
        {
          text: 'Are you sure mass was conserved? What about the bubbling that occurred when the 2 reagents were combined?',
          value: 'c',
          inputName: 'input3',
        },
        {
          text: 'Did you have to add more $\\ce{NaHCO_{3}}$ to get the pH paper color right?',
          value: 'd',
          inputName: 'input4',
        },
      ],
    };
  },
};
</script>
